const colors = [
    "#B21F35",
    "#FFF735",
    "#0052A5",
    "#681E7E",
    "#FF7435",
    "#16DD36",
    "#06A9FC",
    "#00753A",
    "#BD7AF6",
];

export default colors;
